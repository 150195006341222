/* VMX Colors */
@import url(./styles/fonts/TomaSans-Family/TomaSans.css);
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
html, body, #root, #root {
  display: block;
  margin: 0;
  height: 100vh;
  width: 100%; }
  html > div, body > div, #root > div, #root > div {
    margin: 0; }

.Toastify__toast-container--top-center {
  z-index: 99;
  top: 10%;
  width: 98%;
  right: 1%;
  left: unset; }
  .Toastify__toast-container--top-center .Toastify__toast {
    min-height: 35px;
    box-shadow: 0px 3px 6px #0000007E;
    margin-bottom: 5px; }
    .Toastify__toast-container--top-center .Toastify__toast.error-toast {
      background: #ED484F; }
    .Toastify__toast-container--top-center .Toastify__toast.warning-toast {
      background: #F4D52A; }
      .Toastify__toast-container--top-center .Toastify__toast.warning-toast .Toastify__close-button--warning {
        color: black; }
    .Toastify__toast-container--top-center .Toastify__toast.success-toast {
      background: #19BF52; }

.Toastify__toast-container--bottom-right {
  z-index: 99;
  width: 27vw !important; }
  @media only screen and (max-width: 992px) {
    .Toastify__toast-container--bottom-right {
      width: 45vw !important; } }
  @media only screen and (max-width: 768px) {
    .Toastify__toast-container--bottom-right {
      width: 60vw !important; } }
  @media only screen and (max-width: 576px), screen and (max-height: 489px) and (orientation: landscape) {
    .Toastify__toast-container--bottom-right {
      width: 75vw !important; } }
  .Toastify__toast-container--bottom-right .Toastify__toast {
    padding: 0 !important; }
  .Toastify__toast-container--bottom-right svg {
    fill: #805EA8; }
  .Toastify__toast-container--bottom-right .update-toast .update-toast-body {
    margin-left: 5px;
    margin-right: 5px; }
  .Toastify__toast-container--bottom-right .update-toast .update-toast-progress-bar {
    background: #E8308A !important;
    margin: 5px !important; }
