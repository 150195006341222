@font-face {
  font-family: 'TomaSans';
  src: url("Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'TomaSans';
  src: url("Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal; 
}

@font-face {
  font-family: 'TomaSans';
  src: url("Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; 
}

@font-face {
  font-family: 'TomaSans';
  src: url("SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal; 
}