.analyticsNav .dropdown-div .dropdown__content {
  background: white;
  min-width: max-content;
  border: solid 1px black;
  margin-top: 35%;
  padding: 20px; }

.analyticsNav svg {
  font-size: 1.5vw; }

.analyticsNav .title.services {
  width: 8vw; }
