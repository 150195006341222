/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
.app {
  height: 100%; }
  .app .page-title {
    color: #E8308A;
    font-weight: 500;
    font-size: 2vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .app .page-title svg {
      font-size: 3vw; }

body {
  color: #767576;
  background: #ffffff; }

h1, h2, h3, h4, h5, h6 {
  font-family: TomaSans; }

p, a, label, strong, small, span, ul, li, input, option, select, div {
  font-family: TomaSans; }

p, a, label, strong, span, ul, li, input, option, select {
  color: #767576;
  font-size: 15px; }

input, option, select {
  color: #5c5c5c; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li {
  list-style-position: inside;
  list-style-type: disc; }
  li.lh {
    list-style-type: none; }

.container {
  width: 94%;
  margin-right: auto;
  margin-left: auto; }
  @media only screen and (max-width: 992px) {
    .container {
      width: 96%; } }

.pull-right {
  float: right; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  display: inline-block;
  padding: 10px 0;
  text-decoration: none;
  color: #ffffff;
  border: 0;
  border-radius: 3px;
  background: none;
  transition: background-color 0.1s; }
  .btn i {
    padding-right: 5px; }
  .btn:enabled:hover {
    text-decoration: underline; }
  .btn:focus {
    outline: 0; }
  @media only screen and (max-width: 992px) {
    .btn {
      font-size: 1.5vw !important; } }

.btn-danger {
  border: 1px solid;
  padding: 10px 25px; }
  .btn-danger:enabled:hover {
    text-decoration: none; }
  .btn-danger:enabled {
    border-color: #ED484F;
    color: #ED484F; }
    .btn-danger:enabled:hover {
      color: white;
      background: #ED484F; }

.btn-primary {
  border: 1px solid;
  padding: 10px 25px; }
  .btn-primary:enabled:hover {
    text-decoration: none; }
  .btn-primary:enabled {
    border-color: #19BF52;
    color: #19BF52; }
    .btn-primary:enabled:hover {
      color: white;
      background: #19BF52; }

.btn-color-1 {
  border: 1px solid;
  padding: 10px 25px; }
  .btn-color-1:enabled:hover {
    text-decoration: none; }
  .btn-color-1:enabled {
    border-color: #e89916;
    color: #e89916; }
    .btn-color-1:enabled:hover {
      color: white;
      background: #e89916; }

.btn-color-2 {
  border: 1px solid;
  padding: 10px 25px; }
  .btn-color-2:enabled:hover {
    text-decoration: none; }
  .btn-color-2:enabled {
    border-color: #29abe2;
    color: #29abe2; }
    .btn-color-2:enabled:hover {
      color: white;
      background: #29abe2; }

.btn-white {
  border: 1px solid;
  padding: 10px 25px; }
  .btn-white:enabled:hover {
    text-decoration: none; }
  .btn-white:enabled {
    border-color: white;
    color: white; }
    .btn-white:enabled:hover {
      color: #767576;
      background: white; }

.btn-secondary {
  padding: 10px 25px; }

.btn-blue {
  background-color: #005CA9;
  border-radius: 20px;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none;
  color: white;
  border: 1px solid #005CA9; }
  .btn-blue:hover {
    color: #005CA9;
    background-color: white;
    border: 1px solid #005CA9; }
    .btn-blue:hover span, .btn-blue:hover svg {
      color: #005CA9;
      fill: #005CA9; }
  .btn-blue:disabled {
    box-shadow: none;
    opacity: 50%; }
  .btn-blue:active {
    text-decoration: none !important;
    color: #005CA9;
    background-color: white;
    border: 1px solid #005CA9; }
    .btn-blue:active span, .btn-blue:active svg {
      color: #005CA9;
      fill: #005CA9; }
  .btn-blue .MuiSvgIcon-root {
    fill: white; }
  .btn-blue span {
    color: white; }

.btn-magenta {
  background-color: #E8308A; }
  .btn-magenta:hover {
    text-decoration: none !important;
    color: #ffffff;
    background-color: #F397C4; }
  .btn-magenta .MuiSvgIcon-root {
    fill: white; }

.progress {
  background-color: #767576;
  height: 25px;
  width: 350px;
  margin: 0;
  border-radius: 5px;
  border-color: transparent; }
  .progress .progress-bar {
    color: white;
    display: inline-block;
    height: 100%;
    border-radius: 5px;
    transition: width .4s ease-in-out;
    background-color: #19BF52; }
    .progress .progress-bar .progress-value {
      padding-left: 5px; }

.summary .warning {
  color: #F4D52A; }

.summary .error {
  color: #ED484F; }

.summary .property {
  font-weight: 500;
  padding-right: 10px; }

.summary .images .image-item {
  width: fit-content;
  margin: 0 5px; }
  .summary .images .image-item img {
    height: 200px; }

.card {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #9c9b9c;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 5px 0; }

.btn {
  border-radius: 10px;
  font-size: 1vw;
  font-weight: 500;
  box-shadow: 0px 2px 6px black; }

.btn-sm {
  height: 2vw;
  padding: 0;
  line-height: 1; }

.btn-accept {
  width: 100%;
  background-color: #805EA8; }
  .btn-accept:hover {
    text-decoration: none !important;
    color: #ffffff;
    background-color: #CABDDB; }
  .btn-accept:disabled {
    background-color: #805EA8;
    background-color: #CABDDB;
    box-shadow: none;
    opacity: 1; }
  .btn-accept .MuiSvgIcon-root {
    fill: white; }

.btn-cancel {
  width: 100%;
  background-color: #E8308A; }
  .btn-cancel:hover {
    text-decoration: none !important;
    color: #ffffff;
    background-color: #F397C4; }
  .btn-cancel .MuiSvgIcon-root {
    fill: white; }

.btn-menu {
  background-color: white;
  color: #005CA9; }
  .btn-menu:hover {
    background-color: #005CA9;
    color: white; }
    .btn-menu:hover img {
      filter: brightness(0) invert(1); }

footer {
  padding-top: 2rem; }
  footer .footer-inner {
    padding-top: 10px;
    width: 100%;
    background-color: white;
    border-top: 1px solid #E8308A; }

.formLabel {
  display: flex !important;
  align-items: center;
  font-size: 2.7vh;
  padding-bottom: 1vh; }
  @media only screen and (max-width: 768px) {
    .formLabel {
      font-size: 2vh; }
      .formLabel svg {
        width: 0.8em;
        height: 0.8em; } }

.inner-title {
  color: black;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between; }

.inline-form .form-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  width: 100%; }
  .inline-form .form-item span {
    white-space: nowrap;
    color: black; }

.inline-form .submit-button > .form-item {
  justify-content: flex-end; }

.inline-form .form-group {
  display: flex;
  align-items: center;
  width: 100% !important;
  margin: 0;
  padding: 0;
  min-width: 130px; }

.round-border-fields .submit-button {
  align-self: flex-end; }

.round-border-fields .btn-with-icon {
  height: 32px;
  min-width: 103px;
  border-radius: 20px;
  font-size: 1rem;
  white-space: nowrap;
  box-shadow: none;
  outline: 0; }
  .round-border-fields .btn-with-icon button {
    box-shadow: none !important; }
  .round-border-fields .btn-with-icon span {
    padding-right: 1rem;
    padding-left: 1.5rem; }
  .round-border-fields .btn-with-icon .icon {
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    right: -1px;
    top: -1px; }
    .round-border-fields .btn-with-icon .icon svg {
      height: 1.2rem;
      width: 1.2rem; }

.round-border-fields .btn-go {
  background-color: #128082;
  border-radius: 20px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1vw;
  box-shadow: none;
  color: white;
  border: 1px solid #128082;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  display: inline-flex;
  align-items: center; }
  .round-border-fields .btn-go:hover {
    color: #128082;
    background-color: white;
    border: 1px solid #128082; }
    .round-border-fields .btn-go:hover span, .round-border-fields .btn-go:hover svg {
      color: #128082;
      fill: #128082; }
  .round-border-fields .btn-go:disabled {
    box-shadow: none;
    opacity: 50%; }
  .round-border-fields .btn-go:active {
    text-decoration: none !important;
    color: #128082;
    background-color: white;
    border: 1px solid #128082; }
    .round-border-fields .btn-go:active span, .round-border-fields .btn-go:active svg {
      color: #128082;
      fill: #128082; }
  .round-border-fields .btn-go .MuiSvgIcon-root {
    fill: white; }
  .round-border-fields .btn-go span {
    color: white; }
  .round-border-fields .btn-go .icon {
    background: #128082;
    border: 1px solid white; }
  .round-border-fields .btn-go:hover .icon {
    color: #128082;
    background-color: white;
    border: 1px solid #128082; }
  .round-border-fields .btn-go:hover span {
    text-decoration: underline; }
  .round-border-fields .btn-go:disabled .icon {
    opacity: 50%; }
  .round-border-fields .btn-go:active .icon {
    color: #128082;
    background-color: white;
    border: 1px solid #128082; }
  .round-border-fields .btn-go:active span {
    text-decoration: none; }

.round-border-fields .rw-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none; }

.round-border-fields .rw-input, .round-border-fields input {
  border: 1px solid #005CA9 !important;
  border-radius: 20px !important;
  box-shadow: none !important; }

.round-border-fields .rw-widget-input {
  height: 32px; }

.round-border-fields .rw-widget-picker, .round-border-fields .search-by {
  border-radius: 20px !important;
  height: 32px; }

.round-border-fields .rw-widget-container {
  border: none !important; }

.round-border-fields .icon, .round-border-fields .rw-select {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background: #005CA9;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .round-border-fields .icon svg, .round-border-fields .rw-select svg {
    fill: white;
    height: 20px;
    width: 20px; }

.round-border-fields .rw-dropdown-list {
  box-shadow: none; }

.round-border-fields .rw-calendar-header {
  display: flex; }

.with-background {
  background: #D6E6F4;
  margin-bottom: 15px; }
