/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
.form .form-group {
  padding-bottom: 10px; }
  .form .form-group .input-group {
    padding: 0; }
    .form .form-group .input-group img {
      height: 200px; }
    .form .form-group .input-group input, .form .form-group .input-group select {
      margin: 0;
      padding: 8px;
      box-shadow: none;
      background-color: white;
      border: 1px solid #9c9b9c;
      border-radius: 4px; }
    .form .form-group .input-group.input-group-error .rw-widget-input.rw-input,
    .form .form-group .input-group.input-group-error input, .form .form-group .input-group.input-group-error select {
      border: 1px solid #ED484F; }
    .form .form-group .input-group .warning {
      font-size: 12px;
      color: #b97a12; }
    .form .form-group .input-group .react-toggle {
      margin-bottom: -5px;
      /* Optimized with a zoomed-in screen ruler! */ }
      .form .form-group .input-group .react-toggle.react-toggle--checked .react-toggle-track {
        background-color: #19BF52; }
      .form .form-group .input-group .react-toggle.react-toggle--focus .react-toggle-thumb {
        box-shadow: 0 0 2px 3px #e89916; }
      .form .form-group .input-group .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: 0 0 2px 3px #b97a12; }
  .form .form-group .rw-widget .rw-widget-container {
    box-shadow: none;
    border: 1px solid #9c9b9c;
    border-radius: 4px; }
    .form .form-group .rw-widget .rw-widget-container input {
      border: none; }
    .form .form-group .rw-widget .rw-widget-container li {
      background: white;
      border: 1px solid #9c9b9c; }
  .form .form-group.input-group-error .rw-widget .rw-widget-container {
    border: 1px solid #ED484F; }
  .form .form-group.form-group-inline {
    display: inline-block;
    margin-right: 10px; }
    .form .form-group.form-group-inline .error {
      margin-left: 5px;
      display: inline-block; }

.form .fieldarray .fieldarray-item {
  background-color: #efb85b;
  padding: 5px 20px;
  margin: 5px 0;
  border-radius: 5px; }
  .form .fieldarray .fieldarray-item label, .form .fieldarray .fieldarray-item p, .form .fieldarray .fieldarray-item h1, .form .fieldarray .fieldarray-item h2, .form .fieldarray .fieldarray-item h3, .form .fieldarray .fieldarray-item button {
    color: #5c5c5c; }
  .form .fieldarray .fieldarray-item button {
    color: #ED484F; }
  .form .fieldarray .fieldarray-item .input-group-widget {
    max-width: 300px; }
  .form .fieldarray .fieldarray-item .rw-list-option.rw-state-selected,
  .form .fieldarray .fieldarray-item .rw-list-option.rw-state-selected:hover {
    background-color: #efb85b;
    border-color: #efb85b;
    color: #5c5c5c; }

.form .error {
  text-align: left;
  font-size: 0.7vw;
  color: #ED484F;
  display: flex;
  align-items: center; }
  .form .error svg {
    font-size: 0.9vw; }
  @media only screen and (max-width: 992px) {
    .form .error {
      font-size: 1.2vw; }
      .form .error svg {
        font-size: 1.4vw; } }
  @media only screen and (max-width: 576px), screen and (max-height: 489px) and (orientation: landscape) {
    .form .error {
      font-size: 1.7vw; }
      .form .error svg {
        font-size: 2vw; } }
