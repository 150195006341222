/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
.login {
  height: calc(100% - 1.2vw);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -120px;
  text-align: center;
  font-size: 20px;
  background-image: url("../../media/DataPatternBackground.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover; }
  @media only screen and (max-width: 992px) {
    .login .inputs .form-group {
      height: 10vw; }
      .login .inputs .form-group label {
        font-size: 1.5vw; }
      .login .inputs .form-group .input-group input {
        width: 100%;
        height: 4vw;
        font-size: 1.5vw; }
    .login .buttons {
      height: 5.2vw !important; }
    .login img {
      width: 30vw !important; } }
  @media only screen and (max-width: 768px) {
    .login .form-group {
      height: 10vw;
      margin-bottom: 0; }
      .login .form-group.buttons {
        height: 6vw !important; }
      .login .form-group label {
        font-size: 3vw; }
      .login .form-group .input-group input {
        width: 100%;
        height: 4vw;
        font-size: 2vw; } }
  @media only screen and (max-width: 576px), screen and (max-height: 489px) and (orientation: landscape) {
    .login img {
      width: 35vw !important; }
    .login .form-group {
      height: 13vw;
      margin-bottom: 0; }
      .login .form-group label {
        font-size: 2vw !important; }
      .login .form-group.buttons {
        height: 9vw !important; }
      .login .form-group .input-group input {
        width: 100%;
        height: 6vw;
        font-size: 2vw !important;
        height: 3vw; } }
