/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
/* VMX Colors */
.align-bottom {
  display: flex;
  align-items: flex-end; }

.align-center {
  display: flex;
  text-align: center;
  justify-content: center; }

.align-right {
  display: flex;
  justify-content: flex-end; }

.align-left {
  display: flex;
  text-align: left;
  justify-content: flex-start; }

/* Base Colors */
/* Base Colors Dark */
/* Base Colors Light */
/* Font */
.login-form {
  padding-top: 1.5vw;
  padding-left: 4vw;
  padding-right: 3.5vw;
  padding-bottom: 1vw;
  background-color: rgba(255, 255, 255, 0.85);
  color: #ffffff;
  border: solid 1px #805EA8;
  border-radius: 12.5px;
  box-shadow: 0px 0px 8px 0px black; }
  .login-form.error input {
    border: 1px solid #ED484F !important; }
  .login-form label {
    display: block;
    text-align: left;
    color: #805EA8;
    font-size: 1vw;
    font-weight: 500;
    margin-bottom: 0; }
  .login-form input:-webkit-autofill,
  .login-form input:-webkit-autofill:hover,
  .login-form input:-webkit-autofill:focus,
  .login-form input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important; }
  .login-form .inputs {
    height: 13vw; }
  .login-form .form-group {
    height: 6vw;
    margin-bottom: 0;
    margin-top: 5%; }
    .login-form .form-group.buttons {
      height: 4vw; }
  .login-form .input-group {
    display: block; }
    .login-form .input-group input {
      width: 100%;
      height: 2.5vw;
      font-size: 1vw; }
  .login-form .vmx-logo {
    margin-top: 1.6vw;
    margin-bottom: 1.3vw;
    width: 20vw; }
  @media only screen and (max-width: 992px) {
    .login-form .inputs {
      height: 23vw; } }
  @media only screen and (max-width: 768px) {
    .login-form .inputs {
      height: 22vw; } }
  @media only screen and (max-width: 576px), screen and (max-height: 489px) and (orientation: landscape) {
    .login-form .inputs {
      height: 23vw; } }

.form .form-group .input-group input {
  border: 0px;
  outline: none; }

.form .form-group .input-group input:focus {
  border: solid 1px #805EA8; }
